import {QuestionMarkCircleIcon} from '@heroicons/react/20/solid'
import {connect} from "react-redux";
import BackButton from "../Shared/BackButton";
import InputField from "../Shared/InputField";
import {useEffect, useReducer, useState} from "react";
import {createOrderService, getLoggedInUserService} from "../../service";
import {useNavigate} from "react-router-dom";
import AddressForm from "./AddressForm";
import {emptyCart, showLoading} from "../../reducers";
import {calculateVAT, convertNumberToLocale, getTotalWithVAT} from "../../utils";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import LoginButton from "../Login/LoginButton";

function CheckoutForm(props) {

    let subtotal = 0;

    const navigate = useNavigate();
    const [costumer, setCostumer] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [billingAddress, setBillingAddress] = useState({});
    const [showBillingForm, setShowBillingForm] = useState(false);
    const [count, forceUpdate] = useReducer(x => x + 1, 0);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [showCurrentUserDetails, setShowCurrentUserDetails] = useState(true);
    const [validCostumerInfo, setValidCostumerInfo] = useState(false);
    const [validShippingAddress, setValidShippingAddress] = useState(false);
    const [validBillingAddress, setValidBillingAddress] = useState(true);

    function calculateSubTotal(qty, price) {
        subtotal = subtotal + (qty * price);
        return null;
    }

    useEffect(() => {
        if (props.userInfo && props.userInfo.id) {
            props.showLoading(true);
            getLoggedInUserService(props.userInfo.id).then(response => {
                setShowCurrentUserDetails(true);
                if (response.status && response.status !== 200) {
                    console.log("error", response.status);
                    setShowCurrentUserDetails(false);
                    props.showLoading(false);
                } else {
                    let c = {};
                    c.firstName = response.firstName;
                    c.lastName = response.lastName;
                    c.phone = response.phone;
                    c.email = response.email;
                    c.prosjektnummer = response.prosjektnummer;
                    c.merknad = response.merknad;
                    setCostumer(c);
                    setShippingAddress(response.primaryAddress);
                    setValidShippingAddress(true);
                    setBillingAddress(response.primaryBillingAddress);
                    setValidBillingAddress(true)
                    setAllowSubmit(true);
                    props.showLoading(false);
                }
            }).catch(error => {
                console.error("error", error);
                setShowCurrentUserDetails(false);
                props.showLoading(false);
            })
        } else {
            setShowCurrentUserDetails(false);
        }
        window.dataLayer.push({
            event: 'begin_checkout',
            event_category: 'ecommerce',
            event_label: 'checkout',
        });

    }, [props.userInfo])


    const handleInputChange = (input) => {
        costumer[input.id] = input.value.trim();
        setCostumer(costumer);
        validateRequired();
        forceUpdate();
    }

    props.cart.map(item => calculateSubTotal(item.quantity, item.product.discountedPrice>0?item.product.discountedPrice:item.product.price))

    function validateRequired() {
        if (costumer.firstName && costumer.firstName !== ""
            && costumer.lastName && costumer.lastName !== ""
            && costumer.email && costumer.email !== ""
            && costumer.phone && costumer.phone !== "") {
            setValidCostumerInfo(true)
            if (validShippingAddress && validBillingAddress)
                setAllowSubmit(true)
        } else {
            setValidCostumerInfo(false);
            setAllowSubmit(false);
        }
    }

    function handleAddressChange(address) {
        if (address.address1 !== "" && address.postalCode !== "") {
            setValidShippingAddress(true)
            if (validCostumerInfo && validBillingAddress)
                setAllowSubmit(true);
        } else {
            setValidShippingAddress(false);
            setAllowSubmit(false);
        }
        setShippingAddress(address);
    }

    function handleBillingAddressChange(address) {
        if (address.address1 !== "" && address.postalCode !== "") {
            setValidBillingAddress(true)
            if (validCostumerInfo && validShippingAddress)
                setAllowSubmit(true);
        } else {
            setValidBillingAddress(false);
            setAllowSubmit(false);
        }
        setBillingAddress(address);
    }

    function getSendButton() {
        return (<div className={"col-span-full mt-4"}>
            <button
                disabled={allowSubmit !== true}
                onClick={(e) => {
                    setAllowSubmit(false);
                    e.preventDefault();
                    props.showLoading(true)
                    let order = {};
                    order["customer"] = costumer;
                    order["shippingAddress"] = shippingAddress;
                    order["billingAddress"] = billingAddress;
                    if (!showBillingForm)
                        order["billingAddress"] = shippingAddress;
                    order["orderLines"] = props.cart;
                    createOrderService(order).then(response => {
                        if (response.status && response.status!==200) {
                            alert("could not send order")
                            setAllowSubmit(true);
                        }
                        else {
                            props.emptyCart();
                            navigate(`/shop/orders/${response.orderId}`)
                        }
                        props.showLoading(false);
                    }).catch(err => {
                        console.error(err);
                        setAllowSubmit(true);
                        props.showLoading(false);
                    })
                }}
                className="default-ag-button w-full max-w-full disabled:bg-gray-100 disabled:pointer-events-none"
            >
                Neste
            </button>
        </div>)
    }
    return (
        <div className={"container mx-auto"}>
            <BackButton/>
            {props.cart.length > 0 ? <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
                    <h1 className="sr-only">Checkout</h1>

                    {/* Order summary */}
                    <section aria-labelledby="summary-heading"
                             className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 sm:w-96 lg:mt-0 lg:p-8">
                        <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                            Totalt i Handlekurven
                        </h2>

                        <dl className="mt-6 space-y-4">
                            <div className="flex items-center justify-between">
                                <dt className="text-sm text-gray-600">Delsum</dt>
                                <dd className="text-sm font-medium text-gray-900">kr {convertNumberToLocale(subtotal)}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="flex text-sm text-gray-600">
                                    <span>MVA.</span>
                                    <span className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Learn more about how tax is calculated</span>
                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true"/>
                                    </span>
                                </dt>
                                    {props.crmPerson && props.crmPerson.customerType==="B2B"?
                                        <dd className="text-sm font-medium text-gray-900">0</dd>:
                                <dd className="text-sm font-medium text-gray-900">
                                        <span
                                        className={"text-xs font-medium text-gray-500"}>(kr {calculateVAT(subtotal)})</span> 25%
                                </dd>}
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="text-base font-medium text-gray-900">Totalt</dt>
                                <dd className="text-base font-medium text-gray-900 pl-2"> kr {props.crmPerson && props.crmPerson.customerType==="B2B"?subtotal:getTotalWithVAT(subtotal)}</dd>
                            </div>
                        </dl>
                    </section>


                    {/* Checkout form */}
                    <section aria-labelledby="payment-heading"
                             className="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24">
                        <div className="mx-auto max-w-lg">

                            {(!showCurrentUserDetails && !props.userInfo.id) && <div className={"flex flex-col gap-2"}>
                                <span className={"text-black text-lg font-medium"}>Velkommen tilbake</span>
                                <span className={"flex justify-center w-full"}><LoginButton/></span>
                                <span className={"text-black text-lg font-medium text-center py-2 mt-2 border-t"}>Jeg er ny her</span>
                            </div>}
                            {!showCurrentUserDetails ?
                                <form className="">
                                    <div className="grid grid-cols-12 gap-y-6 gap-x-4">

                                        <div className="col-span-full">
                                            <InputField id={"firstName"} label={"Navn"}
                                                        value={costumer.firstName ? costumer.firstName : ""} type={"text"}
                                                        autocomplete={"first-name"} required={true}
                                                        handleChange={handleInputChange}/>
                                        </div>

                                        <div className="col-span-full">
                                            <InputField id={"lastName"} label={"Etternavn"} type={"text"}
                                                        value={costumer.lastName ? costumer.lastName : ""}
                                                        autocomplete={"family-name"} required={true}
                                                        handleChange={handleInputChange}/>
                                        </div>
                                        {props.crmPerson && !props.crmPerson.email &&<div className="col-span-full">
                                            <InputField id={"email"} label={"Epost"} type={"email"}
                                                        autocomplete={"email"}
                                                        value={costumer.email ? costumer.email : ""}
                                                        required={true} handleChange={handleInputChange}/>
                                        </div>}

                                        <div className="col-span-full">
                                            <InputField id={"phone"} label={"Mobil"} type={"tel"} autocomplete={"phone"}
                                                        value={costumer.phone ? costumer.phone : ""}
                                                        required={true} handleChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className={"mt-10"}>
                                        <h3 className="text-lg font-medium text-gray-900">Leveringsadresse</h3>
                                        <div className={"grid grid-cols-12 gap-y-6 gap-x-4 "}>
                                            <AddressForm key={`shipping-address`} handleAddressChange={handleAddressChange}
                                                         current={shippingAddress}/>
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        <h3 className="text-lg font-medium text-gray-900">Fakturaadresse</h3>

                                        <div className="mt-6 flex items-center">
                                            <input
                                                id="same-as-shipping"
                                                name="same-as-shipping"
                                                type="checkbox"
                                                onChange={(e) => {
                                                    setShowBillingForm(!e.target.checked)
                                                    setValidBillingAddress(e.target.checked);
                                                    if (!e.target.checked)
                                                        setAllowSubmit(false);
                                                    else {
                                                        if (validCostumerInfo && validShippingAddress)
                                                            setAllowSubmit(true);
                                                    }
                                                    forceUpdate();
                                                }}
                                                checked={showBillingForm === false}
                                                className="h-4 w-4 rounded border-gray-300 text-yellow focus:ring-yellow"
                                            />
                                            <div className="ml-2">
                                                <label htmlFor="same-as-shipping"
                                                       className="text-sm font-medium text-gray-900">
                                                    Samme som leveringsadressen
                                                </label>
                                            </div>
                                        </div>
                                        {showBillingForm && <div className={"grid grid-cols-12 gap-y-6 gap-x-4 "}>
                                            <AddressForm key={"billing-address"} current={billingAddress}
                                                         handleAddressChange={handleBillingAddressChange}/>
                                        </div>}
                                        <div className={"flex flex-col gap-y-6 mt-6"}>
                                            <div className="col-span-full">
                                                <InputField id={"prosjektnummer"} label={"Prosjektnummer"} type={"text"}
                                                            autocomplete={"prosjektnummer"}
                                                            value={""}
                                                            required={false} handleChange={handleInputChange}/>
                                            </div>
                                            <div className="col-span-full">
                                                <InputField id={"merknad"} label={"Faktura Merknad"} type={"text"}
                                                            autocomplete={"fakturaMerknad"}
                                                            value={""}
                                                            required={false} handleChange={handleInputChange}/>
                                            </div>
                                        </div>
                                    </div>
                                    {getSendButton()}
                                </form>
                                :
                                Object.keys(costumer).length > 0 &&
                                <div className={"flex flex-col gap-2"}>
                                    <div className={"flex flex-col rounded border divide-y"}>
                                        <span className={"bg-slate-100 text-gray-900 text-lg p-2"}>Detaljer</span>
                                        <div className={"flex w-full divide-x"}>
                                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                                className={"text-xs text-gray-400"}>Navn:</span>{costumer.firstName}</div>
                                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                                className={"text-xs text-gray-400"}>Etter navn:</span>{costumer.lastName}</div>
                                        </div>
                                        <div className={"flex items-center gap-3 p-2"}><span
                                            className={"text-xs text-gray-400"}>Mobil:</span>{costumer.phone}</div>
                                        <div className={"flex items-center gap-3 p-2"}><span
                                            className={"text-xs text-gray-400"}>Epost:</span>{costumer.email}</div>
                                        {props.crmPerson.crmCompanyName&&<div className={"flex items-center gap-3 p-2"}><span
                                            className={"text-xs text-gray-400"}>Kunde:</span>{props.crmPerson.crmCompanyName}</div>}
                                    </div>
                                    <div className={"flex flex-col rounded border divide-y"}>
                                        <span className={"bg-slate-100 text-gray-900 text-lg p-2"}>Leveringsadresser</span>
                                        <div className={"flex items-center gap-3 p-2"}><span
                                            className={"text-xs text-gray-400"}>Address:</span>{shippingAddress.address1}</div>
                                        <div className={"flex w-full divide-x"}>
                                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                                className={"text-xs text-gray-400"}>Postal code:</span>{shippingAddress.postalCode}
                                            </div>
                                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                                className={"text-xs text-gray-400"}>Postal Area:</span>{shippingAddress.postalArea}
                                            </div>
                                        </div>
                                        <div
                                            className={"flex items-center gap-3 p-2"}>{shippingAddress.city} {shippingAddress.country} </div>
                                    </div>
                                    <div className={"flex flex-col rounded border divide-y"}>
                                        <span className={"bg-slate-100 text-gray-900 text-lg p-2"}>Faktureringsadresser</span>
                                        <div className={"flex items-center gap-3 p-2"}><span
                                            className={"text-xs text-gray-400"}>Address:</span>{billingAddress.address1}</div>
                                        <div className={"flex w-full divide-x"}>
                                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                                className={"text-xs text-gray-400"}>Postal code:</span>{billingAddress.postalCode}
                                            </div>
                                            <div className={"w-1/2 flex items-center gap-3 p-2"}><span
                                                className={"text-xs text-gray-400"}>Postal Area:</span>{billingAddress.postalArea}
                                            </div>
                                        </div>
                                        <div
                                            className={"flex items-center gap-3 p-2"}>{billingAddress.city} {billingAddress.country} </div>
                                    </div>
                                    <div className={"flex flex-col rounded border divide-y"}>
                                        <span className={"bg-slate-100 text-gray-900 text-lg p-2"}>Prosjektnummer / Faktura Merknad</span>
                                        <div className={"flex items-center gap-3 p-2"}><span
                                            className={"text-xs text-gray-400"}>Prosjektnummer:</span>{costumer.prosjektnummer}</div>
                                        <div className={"flex items-center gap-3 p-2"}><span
                                            className={"text-xs text-gray-400"}>Merknad:</span>{costumer.merknad}</div>
                                    </div>
                                    <div className="mt-6 flex items-center">
                                        <input
                                            id="alter-information"
                                            name="alter-information"
                                            type="checkbox"
                                            onChange={(e) => {
                                                setShowCurrentUserDetails(!e.target.checked)
                                                if (!e.target.checked)
                                                    setAllowSubmit(false);
                                                else {
                                                    setAllowSubmit(true);
                                                }
                                                forceUpdate();
                                            }}
                                            className="h-4 w-4 rounded border-gray-300 text-yellow focus:ring-yellow"
                                        />
                                        <div className="ml-2">
                                            <label htmlFor="alter-information" className="text-sm font-medium text-gray-900 cursor-pointer">
                                                Endre informasjon
                                            </label>
                                        </div>
                                    </div>
                                    {getSendButton()}
                                </div>
                            }
                            <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                            <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                Alle priser er eksklusiv mva, ved å klikke på neste godtar du å betale mva.
                        </p>
                        </div>
                    </section>
                </main>
                :
                <main className={"lg:flex lg:min-h-full"}>
                    <div className={"my-8 w-full p-8 text-center shadow font-bold text-gray-600"}>Handlekurven er tom
                    </div>
                </main>}
        </div>
    )
}

const mapStateToProps = ({cart, userInfo,crmPerson}) => {
    return {cart: cart, userInfo: userInfo,crmPerson:crmPerson}
}
export default connect(mapStateToProps, {emptyCart, showLoading})(CheckoutForm)